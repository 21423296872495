/* .test {
    width: 100wh;
    height: 100vh;
    color: #fff;
    background: linear-gradient(-45deg, #8b8380,#6277a6);
    background: linear-gradient(-45deg, #bd9487, #bf8ca0, #93a8d5, #a1d5c9);
    background-size: 400% 400%;
    -webkit-animation: Gradient 15s ease infinite;
    -moz-animation: Gradient 15s ease infinite;
    animation: Gradient 15s ease infinite;
    }
  
  .paused {
    -webkit-animation-play-state: paused; Safari 4.0 - 8.0
    animation-play-state: paused;
  }
  
  @-webkit-keyframes Gradient {
    0% {
    background-position: 0% 50%
    }
  50% {
  background-position: 100% 50%
  }
  100% {
  background-position: 0% 50%
  }
  }
  
  @-moz-keyframes Gradient {
    0% {
    background-position: 0% 50%
    }
  50% {
  background-position: 100% 50%
  }
  100% {
  background-position: 0% 50%
  }
  }
  
  @keyframes Gradient {
    0% {
    background-position: 0% 50%
    }
  50% {
  background-position: 100% 50%
  }
  100% {
  background-position: 0% 50%
  }
  }
  
*/

 .rotate {
    animation: rotation 5s infinite linear;
  }

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  } 

